import './process';
// Add support for ie11
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react"
import { hydrate, render } from "react-dom";
import App from "./App"
import "./static/style.css"
import './styles/global.scss'
import { ApolloClient, InMemoryCache, HttpLink, ApolloProvider, ApolloLink } from "@apollo/client"
import { Provider } from "react-redux"
import store from "./store"
import { GET_USER_DETAILS, GET_CONFIG } from "./queries"
import { loadUser } from "./reducers/userReducer"
import { loadConfig } from './reducers/configReducer';
import LogRocket from 'logrocket'
import { ThemeProvider } from 'styled-components/macro';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from "react-ga"
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { MsalProvider } from '@azure/msal-react';


// --- Microsoft Login --- //

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

// --- Microsoft Login --- //

// Fix __typename errors
const omitTypename = (key, value) => {
    return key === '__typename' ? undefined : value
}

const omitTypenameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        operation.variables = JSON.parse(
            JSON.stringify(operation.variables),
            omitTypename
        )
    }
    return forward(operation)
})

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
    credentials: "include"
})

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([omitTypenameLink, httpLink])
})
// 1. Get config
client.query({ query: GET_CONFIG })
    .then(({ data }) => store.dispatch(loadConfig(data.getConfig)))
    .catch(e => { })
    // 2. Fetch user details and save in redux store
    .then(_ => client.query({ query: GET_USER_DETAILS })
        .then(({ data }) => {
            const user = data?.getUserDetails
            store.dispatch(loadUser(user))
        })
        .catch(e => { })
    )
    // 3. Render app
    .finally(_ => {
        // Initialize analytics if cookie preferences allow it
        if (process.env.NODE_ENV !== "development" && localStorage.getItem("cookies") === "true") {
            // Google Analytics
            if (store.getState().config.googleAnalyticsTrackingID)
                ReactGA.initialize(store.getState().config.googleAnalyticsTrackingID)

            // LogRocket
            LogRocket.init('zev0ns/mathsadvance')
        }

        const root = document.getElementById("root")
        const app = <ApolloProvider client={client}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <MsalProvider instance={msalInstance}>
                    <Provider store={store}>
                        <ThemeProvider theme={{ primary: store.getState().config.primaryColor }}>
                            <Router>
                                <App />
                            </Router>
                        </ThemeProvider>
                    </Provider>
                </MsalProvider>
            </GoogleOAuthProvider>
        </ApolloProvider>
        if (root.hasChildNodes()) hydrate(app, root)
        else render(app, root)
    })